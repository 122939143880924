import React from 'react';
import * as Styled from './styled';

export const Container: React.FC<React.HtmlHTMLAttributes<HTMLDivElement> & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return <Styled.Container {...rest}>{children}</Styled.Container>;
};

export const FeatureHeading: React.FC<{
  title: string;
  subtitle?: React.ReactNode | string;
  children?: React.ReactNode;
  noBorder?: boolean;
}> = ({ title, subtitle, children, noBorder }) => {
  return (
    <Styled.FeatureHeading $noBorder={noBorder}>
      <div className="feature-heading-content">
        <h5>{title}</h5>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
      {children}
    </Styled.FeatureHeading>
  );
};

const PageLayout: React.FC<
  React.HtmlHTMLAttributes<HTMLDivElement> & { leftSideBar?: React.ReactNode; children?: React.ReactNode }
> = ({ leftSideBar, children, ...rest }) => {
  if (!leftSideBar) {
    return <Container {...rest}>{children}</Container>;
  }

  return (
    <Styled.PageLayout {...rest}>
      {leftSideBar && <Styled.LeftSideBar>{leftSideBar}</Styled.LeftSideBar>}
      <main>{children}</main>
      <div>{}</div>
    </Styled.PageLayout>
  );
};

export default PageLayout;
