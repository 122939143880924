'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const PageLayout = styled.div`
  flex: 1;
  display: flex;

  main {
    flex: 1;
    padding: 25px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    padding: 15px 10px;
    width: 100%;
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 15px 10px;
    width: 100%;
  }
`;

export const FeatureHeading = styled.div<{ $noBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > .feature-heading-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .subtitle {
    /* font-size: 16px; */
    color: ${colors.secondary.darkGray1};
    max-width: 120ch;
    line-height: 1.5;
    text-wrap: balance;
  }
  padding-bottom: 15px;
  border-bottom: ${({ $noBorder }) => ($noBorder ? 'none' : `1px solid ${colors.secondary.lightGray3}`)};

  button {
    white-space: nowrap;
  }
`;

export const Container = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 50px;
  gap: 25px;

  @media (max-width: ${breakPoints.tablet}) {
    max-width: auto;
    padding: 10px;
  }

  @media (max-width: ${breakPoints.mobile}) {
    max-width: auto;
    padding: 15px 10px;
  }
`;

export const LeftSideBar = styled.div`
  background-color: ${colors.secondary.lightGray1};
  min-width: 384px;
  max-width: 384px;
  padding: 35px 25px;

  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }

  @media (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;
