'use client';
import Link from 'next/link';

import * as Styled from './styled';
import * as Icon from '@/ui/Icon';

// const externalFaqLink = 'https://oxenai.notion.site/Oxen-FAQs-0b7ce10f5c794ab69f88a916bcab5e06';

interface FooterProps {}

const Footer: React.FC<React.HTMLProps<HTMLDivElement> & FooterProps> = ({ ...rest }) => {
  return (
    <Styled.Footer {...rest}>
      <Styled.FooterContent>
        <div style={{ whiteSpace: 'nowrap' }}>
          Copyright &copy; {new Date().getFullYear()} Oxen.ai, All Rights Reserved
        </div>
        <Styled.FooterLinks>
          <Styled.LinkGroup>
            <Link href="https://medium.com/@oxenai" target="_blank">
              <Icon.SocialMedium size="24px" />
            </Link>
            <Link href="https://twitter.com/oxen_ai" target="_blank">
              <Icon.SocialTwitter size="24px" />
            </Link>
            <Link href="https://www.linkedin.com/company/oxenai/" target="_blank">
              <Icon.SocialLinkedin size="24px" />
            </Link>
            <Link href="https://github.com/Oxen-AI" target="_blank">
              <Icon.SocialGithub size="24px" />
            </Link>
          </Styled.LinkGroup>
          <Styled.LinkGroup>
            {/* <Link href={externalFaqLink} target="_blank" rel="noopener noreferrer" className="hide-mobile">
              FAQ
            </Link> */}
            <Link href="/jobs" prefetch={false} target="_blank" rel="noopener noreferrer">
              Careers
            </Link>
            <Link href="/about/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
            <Link href="/about/terms_of_service" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </Link>
          </Styled.LinkGroup>
        </Styled.FooterLinks>
      </Styled.FooterContent>
    </Styled.Footer>
  );
};

export default Footer;
